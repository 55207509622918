
const month = {
  1: 'Januari',
  2: 'Februari',
  3: 'Mars',
  4: 'April',
  5: 'Maj',
  6: 'Juni',
  7: 'Juli',
  8: 'Augusti',
  9: 'September',
  10: 'Oktober',
  11: 'November',
  12: 'December',
}

/**
 * Handles prismic 'date' fields and returns a swedish readable date.
 */
export const formatToSwedishDate = (date) => {
  const [y,m,d] = date.split('-');
  return `${d} ${month[m]} ${y}`;
}