import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InternalLink from '../stuff/InternalLink';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: 78,
    backgroundColor: theme.palette.primaryPink.main,
  },
  innerContainer: {
    paddingLeft: 64,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }
}));

export default function BlogPostBredcrums({ category, blogPost }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
        <div className={classes.innerContainer}>          
          <InternalLink to={category.url}>
            <Typography style={{ textTransform: 'uppercase' }} variant="h6">
              {category.document.data.display_name}
            </Typography>
          </InternalLink>
          <span style={{paddingLeft: 8, paddingRight: 8}}>-</span>
          <Typography style={{ textTransform: 'uppercase' }} variant="h6">
            {blogPost}
          </Typography>
        </div>
    </div>
  );
}
