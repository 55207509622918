import * as React from 'react'
import { withUnpublishedPreview } from 'gatsby-source-prismic'
import BlogPost from '../page-templates/BlogPost'

const NotFoundPage = () => (
  <>
    <h1>Sidan hittades inte :(</h1>
  </>
)

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    'blog_post': BlogPost,
  },
})