import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../components/navbar/navbar'
import Footer from '../components/footer/footer';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import SliceZone from '../slices/SliceZone';
import Img from 'gatsby-image';
import { Typography } from '@material-ui/core';
import ResponsiveMarginContainer from '../components/containers/responsiveMarginContainer';
import AuthorCard from '../components/blog/AuthorCard';
import BlogPostBredcrums from '../components/blog/BlogPostBredcrums';
import { formatToSwedishDate } from '../prismic/utils/dateFormatter'
import { withPreview } from 'gatsby-source-prismic'

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: 67,
    [theme.breakpoints.up('lg')]: {
      paddingTop: 85,
    },
    width: '100%',
  },
  blogContainer: {
    paddingTop: 32,
    [theme.breakpoints.up('md')]: {
      paddingTop: 64,
    },
    width: '100%',
    backgroundColor: '#FDFBFE',
    display: 'flex',
    flexDirection: 'column',
  },
  imageContainer: {
    alignSelf: 'center',
    width: '98%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    height: '100%'
  },
  titleContainer: {

  },
}));

const BlogPost = ({location, data}) => {

  const classes = useStyles();
  const blogPostData = data.prismicBlogPost.data;
  const author = blogPostData.author.document.data;

  return (
    <>
      <SEO
        title={blogPostData.meta_title}
        description={blogPostData.meta_description}
        socialImage={blogPostData.social_image.url}
      /> 
      <Navbar currentPath={location.pathname} />
      <div className={classes.container}>
        <BlogPostBredcrums blogPost={blogPostData.title} category={blogPostData.blog_category} />
        <div className={classes.blogContainer}>
          <div className={classes.imageContainer}>
            <Img fluid={blogPostData.thumbnail_image.fluid}/>
          </div>
          <ResponsiveMarginContainer
              desktopSizes={{
                width: '70%',
                top: 64,
                bottom: 32,
              }}
              mobileSizes={{
                width: '88%',
                top: 32,
                bottom: 32,
              }}>
            <div className={classes.titleContainer} >
             <Typography variant="button">
                  {formatToSwedishDate(blogPostData.published_date)}
              </Typography>
              <Typography displayBlock style={{ paddingTop: 12 }} variant="h2">
                  {blogPostData.title}
              </Typography>
            </div>
          </ResponsiveMarginContainer>
        </div>
        <SliceZone slices={blogPostData.body} />
        <ResponsiveMarginContainer
          desktopSizes={{
            width: '70%',
            top: 0,
            bottom: 64,
          }}
          mobileSizes={{
            width: '88%',
            top: 0,
            bottom: 32,
          }}>
          <AuthorCard author={author}/>
        </ResponsiveMarginContainer>
        </div>
      <Footer colorTheme="dark" paddingTop={48} />
    </>
  );
}

export const query = graphql`
  query BlogPostQuery($uid: String) {
    prismicBlogPost(uid: {eq: $uid}) {
      data {
        meta_title
        meta_description
        social_image {
          url
        }
        published_date
        title
        thumbnail_image {
          alt
          fluid(maxWidth: 1000, maxHeight: 600) {
            ...GatsbyPrismicImageFluid
          }
        }
        blog_category {
          url
          document {
            ... on PrismicBlogcategory {
              data {
                display_name
              }
            }
          }
        }
        author {
          document {
            ... on PrismicAuthor {
              id
              data {
                description
                image {
                  alt
                  fixed(width: 120) {
                    ...GatsbyPrismicImageFixed
                  }
                }
                name {
                  text
                }
              }
            }
          }
        }
        body {
          ... on PrismicBlogPostBodyCodesnippet {
              slice_type
              primary {
                file_name
                code {
                  html
                  text
                  raw
                }
              }
          }
          ... on PrismicBlogPostBodyFullwidthimage {
            slice_type
            primary {
              image {
                alt
                fluid(maxWidth: 1440, maxHeight: 1000) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicBlogPostBodyTextarea {
            slice_type
            primary {
              headline
              two_columns
              text {
                html
              }
            }
          }
          ... on PrismicBlogPostBodyQuote {
            slice_type
            primary {
              quote
            }
          }
          ... on PrismicBlogPostBodyFullwidthimage {
            slice_type
          }
          ... on PrismicBlogPostBodyCenteredimage {
            slice_type
            primary {
              image {
                alt
                fluid(maxWidth: 1000, maxHeight: 1000) {
                  ...GatsbyPrismicImageFluid
                }
              }
              max_width
            }
          }
        }
      }
    }
  }
`



export default withPreview(BlogPost);