import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Img from 'gatsby-image';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    maxWidth: 650,
    border: theme.custom.standardBorder,
    backgroundColor: theme.palette.primaryPink.light1,
  },
  nameContainer: {
    borderBottom: theme.custom.standardBorder,
    padding: 16,
    paddingLeft: 24,
  },
  cardContentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    alignItems: 'center',
    justifyContent: 'center'
  },
  imageContainer: {
    padding: 32,
  },
  image: {
    maxWidth: 120,
    maxHeight: 192,
    height: '100%',
  },
  textContainer: {
    padding: 32,
    paddingTop: 0,
    [theme.breakpoints.up('sm')]: {
      padding: null,
      paddingRight: 32,
    },
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

export default function AuthorCard({ author }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.nameContainer}>
        <Typography style={{textTransform: 'uppercase'}} variant="h5">
          skrivet av {author.name.text}
        </Typography>
      </div>
      <div className={classes.cardContentContainer}>
        <div className={classes.imageContainer}>
            <Img 
              className={classes.image}
              alt={author.image.alt} fixed={author.image.fixed}
            />
        </div>
        <div className={classes.textContainer}>
          <div>
            <Typography style={{textTransform: 'uppercase', paddingBottom: 16}} variant="h6">
              OM {author.name.text}
            </Typography>
            <Typography>
              {author.description}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}